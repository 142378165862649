import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './assets/style/bootstrap-4.4.1.css';
import './assets/style/style.css';
import Home from './pages/Home';
import React, { useState, useEffect } from 'react';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Cookie from './components/Cookie';
import "./App.css"

import GamePage from './pages/GamePage';






function App() {
 
  return (
    <div>
      
     <Router>
       
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/contactus" element={<Home/>}/>
          <Route path="/playnow" element={<Home/>}/>
          <Route path="/joinus" element={<Home/>}/>
          <Route path="/offers" element={<Home/>}/>
          
          <Route path="/play" element={<GamePage/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/cookie" element={<Cookie/>}/>
        </Routes>
      
      </Router>
    </div>
  );
}

export default App;
